<template>
  <div class="lottery">
    <div class="lotto-wrapper">
      <div id="select-lotto">
        <p>Select a lottery:</p>
        <v-select
          :value="currentLottery.price"
          :options="getOptions"
          @input="setCurrentLottery"
        ></v-select>
      </div>
      <lottery-box 
        v-for="lottery in lotteries"
        :key="lottery.address"
        :lottery="lottery"
        :class="{hidden: lottery.address != currentLottery.address}"/>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import LotteryBox from '../components/LotteryBox.vue';

import { lotteries } from "../util/lotteries.json";
import { web3Util } from "../util/getWeb3.js";

export default {
  name: "Lottery",
  components: {
    vSelect,
    LotteryBox
  },
  data() {
    return {
      lotteries: this.getLotteries(),
      currentLottery: null, 
    };
  },
  computed: {
    getOptions() {
      return lotteries.map((lotto) => {
        return {
          address: lotto.address,
          price: lotto.price,
          label: lotto.price + ' - ' + lotto.entries + ' entries'
        }
      })
    }
  },
  methods: {
    getLotteries() {
      return lotteries.map((lotto) => {
        return {
          address: lotto.address,
          price: lotto.price,
          contract: web3Util.getLottery(lotto.address)
        }
      })
    },
    setCurrentLottery(lottery) {
      this.currentLottery = {
        address: lottery.address,
        price: lottery.price,
      }
    }
  },
  created() {
    let lottery = lotteries[0]
    this.currentLottery = {
      contract: web3Util.getLottery(lottery.address),
      address: lottery.address,
      price: lottery.price,
    }
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";

.hidden {
  display: none;
}

.lotto-wrapper {
  width: 340px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .v-select {
    .vs__dropdown-toggle {
      border: none;
      border-bottom: 1px solid black;
      border-radius: 0;
    }

    .vs__open-indicator {
      fill: black;
    }

    .vs__selected {
      font-size: 20px;
    }

    .vs__clear {
      display: none;
    }
  }
}

@media (max-width: 680px) {
  .lotto-wrapper {
    width: 90%;
  }
}
</style>
