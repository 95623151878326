<template>
  <div id="app">
    <Navigation/>
    <div class="no-web3" v-if="!store.isWeb3">
      <div>
        Your browser does not have a wallet provider. <br/>
        Consider installing <a href="https://metamask.io">Metamask</a>
      </div>
    </div>
    <div class="no-web3" v-else-if="store.network != 250">
      <div>
        Wrong network.<br/>
        Make sure you're connected to the Fantom Opera network.
      </div>
    </div>
    <div id="view" v-else>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Navigation from './components/Navigation.vue'

export default {
  name: "app",
  components: {
    Navigation
  }
}
</script>


<style lang="scss">
body {
  margin: 0;
}

a {
  color: #000;
}

.no-web3 {
  position: fixed;
  height: 100%;
  width: 100%;
  text-align: center;
  div {
    position: relative;
    width: 75%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }
}

#app {
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#view {
  padding-top: 70px;
}
</style>
