<template>
  <div class="lotto-box">
    <button 
      v-if="!store.isConnected"
      class="main-action" 
      id="not-connected">
      Connect wallet.
    </button>
    <button
      v-else-if="isLoading"
      class="main-action" 
      id="loading">
      Loading...
    </button>
    <button
      v-else-if="!isBalanceSufficient"
      class="main-action"
      id="insufficient">
      Insufficient balance.
    </button>
    <button
      v-on:click="approve()"
      class="main-action"
      id="approve"
      v-else-if="!isApproved">
      Approve
    </button>
    <button 
      v-else
      v-on:click="enter()" 
      class="main-action" 
      id="enter">
      Enter
    </button>
    <div id="progress-container">
      <div :style="`width:${progress}`"></div>
    </div>
    <div id="entry-info">
      <div id="entries">{{ entriesLeft }}</div>
      <div id="won-amount">Won rounds: {{ won }}</div>
    </div>
  </div>
</template>

<script>
import { store, mutations } from "../store.js";
import { web3Util } from "../util/getWeb3.js";

export default {
  name: 'LotteryBox',
  props: {
    lottery: Object
  },
  data() {
    return {
      approvedAmount: null,
      entries: {},
      wonAmount: null,
    };
  },
  computed: {
    isBalanceSufficient() {
      return this.lottery.price < store.balance;
    },

    isApproved() {
      return this.lottery.price <= this.approvedAmount;
    },

    isLoading() {
      if (store.balance == null || this.approvedAmount == null) {
        return true;
      } else {
        return false;
      }
    },

    progress() {
      if(this.entries.left != null || this.entries.max != null) {
        return `${[(this.entries.max - this.entries.left) / this.entries.max] * 100}%`;
      } else {
        return 0;
      }
    },

    won() {
      if(this.wonAmount == null) {
        return "#";
      } else {
        return this.wonAmount
      }
    },

    entriesLeft() {
      if(this.entries.max != null && this.entries.left != null) {
        return `Entries left: ${this.entries.left}/${this.entries.max}`
      } else {
        return `Loading...`
      }
    }
  },
  methods: {
    async update() {
      this.$emit('updating')
      this.approvedAmount = null;
      this.entries = {
        max: null,
        left: null
      };
      this.wonAmount = null;

      this.$on('updating', () => {
        return;
      })

      await this.getApprovedAmount();
      await this.getEntries();
      await this.getWonAmount();
    },

    async getApprovedAmount() {
      return (this.approvedAmount = await web3Util.getApprovedAmount(
        this.lottery.address
      ));
    },

    async getEntries() {
      let result = {
        max: await this.lottery.contract.methods.maxEntries().call(),
        left: await this.lottery.contract.methods.getRemainingEntries().call()
        };
      return (this.entries = result);
    },  

    async approve() {
      await web3Util.approveBomb(this.lottery.address, store.balance);
      await this.getApprovedAmount();
    },

    async enter() {
      await this.lottery.contract.methods
        .enter()
        .send({ from: store.account });
      mutations.updateAccount();
      await this.getApprovedAmount();
      await this.getEntries();
    },

    async getWonAmount() {
      return (this.wonAmount = await web3Util.getWonAmount(this.lottery.contract));
    },

    setEventListener() {
      this.lottery.contract.events.Win().on("data", () => {
        this.getEntries();
        this.getWonAmount();
      });

      this.lottery.contract.events.Entry().on("data", () => {
        this.getEntries();
      });
    },
  },
  created() {
    this.update();
  },
};  

</script>

<style lang="scss">
.main-action {
  width: 100%;
  height: 65px;
  border-radius: 300px;
  margin-top: 20px;
  font-family: "Raleway", sans-serif;
  font-size: 25px;

  -webkit-box-shadow: inset 0px 0px 0px 1px #000;
  -moz-box-shadow: inset 0px 0px 0px 1px #000;
  box-shadow: inset 0px 0px 0px 1px #000;

  border-radius: 100vh;
  border: none;
  background: rgb(255, 255, 255);
  transition: 500ms;

  &#enter,
  &#approve {
    &:hover {
      -webkit-box-shadow: inset 0px 0px 0px 2px #000;
      -moz-box-shadow: inset 0px 0px 0px 2px #000;
      box-shadow: inset 0px 0px 0px 2px #000;
      border: none;
    }

    &:hover {
      background: rgb(204, 213, 243);
      transition: none;
    }
  }

  &#loading,
  &#insufficient,
  &#not-connected {
    color: #808080;
    -webkit-box-shadow: inset 0px 0px 0px 1px #808080;
    -moz-box-shadow: inset 0px 0px 0px 1px #808080;
    box-shadow: inset 0px 0px 0px 1px #808080;
  }
}

#progress-container {
  width: 100%;
  height: 3px;
  background: #d3d3d3;
  border-radius: 3px;
  margin-top: 20px;
  div {
    background: rgb(33, 111, 255);
    height: 100%;
    animation: 500ms ease-in-out;
  }
}

#entry-info {
  > * {
    position: absolute;
    width: auto;
    margin-top: 5px;
  }

  #entries {
    right: 0;
  }

  #won-amount {
    left: 0;
  }
}
</style>