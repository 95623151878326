import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { web3Util } from './util/getWeb3.js'
import { store, mutations } from './store.js'

Vue.config.productionTip = false

Vue.mixin({
  computed: {
    store () {
      return store
    },
    mutations () {
      return mutations
    }
  }
})

new Vue({
  created: async () => {
    store.isWeb3 = web3Util.checkIfHasProvider();
    await mutations.initializeWeb3()
    if(window.web3) {
      mutations.updateAccount()
      window.ethereum.on('accountsChanged', (accounts) => {
        mutations.updateAccount()
        console.log(store.account)
        if(!accounts[0]) {
          store.balance = null
          store.isConnected = false
          store.account = null
        }
      })
      window.ethereum.on('networkChanged', () => {
        mutations.updateAccount()
      })
    }
  },
  router,
  render: h => h(App)
}).$mount('#app')
