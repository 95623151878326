<template>
  <div id="nav">
    <nav>
      <img id="logo" src="../assets/bomb-logo.svg">
      <div class="links">
        <button 
          v-if="isMobile"
          class="hamburger hamburger--collapse" 
          type="button"
          v-on:click="toggleHamburger"
          v-bind:class="{'is-active': hamburgerIsActive}">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
        <div class="desktop" v-if="!isMobile">
          <router-link to="/">Lottery</router-link>
          <router-link to="/about">About</router-link>
          <button class="connect" v-on:click="mutations.initializeWeb3()">
            <span>{{ connectButton }}</span>
          </button>
        </div>
      </div>
      <div class="mobile" v-if="isMobile">
        <button class="connect" v-on:click="mutations.initializeWeb3()">
          <span>{{ connectButton }}</span>
        </button>
        <div class="links" v-bind:class="{active: hamburgerIsActive}">
          <router-link to="/">Lottery</router-link>
          <router-link to="/about">About</router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {
      windowWidth: window.innerWidth,
      hamburgerIsActive: false
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  computed: {
    connectButton() {
      let account = this.store.account
      if(!account) {
        return "Connect"
      } else {
        return account
      }
    },
    isMobile() {
      return this.windowWidth <= 680;
    }
  },
  methods: {
    toggleHamburger() {
      this.hamburgerIsActive = !this.hamburgerIsActive;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "hamburgers/_sass/hamburgers/hamburgers.scss";
nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  transition: 500ms;
  z-index: 1;

  #logo {
    position: relative;
    height: 40%;
    left: 30px;
    top: 47%;
    transform: translateY(-50%);
  }
}

.hamburger {
  opacity: 0.50;
  transition: 500ms;
  padding-right: 30px;

  &::hover {
    opacity: 0.70
  }

}

.connect:hover {
  -webkit-box-shadow:inset 0px 0px 0px 2px #000;
  -moz-box-shadow:inset 0px 0px 0px 2px #000;
  box-shadow:inset 0px 0px 0px 2px #000;
  border: none;
}

.connect {
  -webkit-box-shadow:inset 0px 0px 0px 1px #000;
  -moz-box-shadow:inset 0px 0px 0px 1px #000;
  box-shadow:inset 0px 0px 0px 1px #000;
  
  font-family: 'Anonymous Pro', monospace;
  width: 90px;
  border-radius: 100vh;
  padding: 10px;
  border: none;
  background: white;
  transition: 500ms;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  }
}

.links {
  margin: 0;
  height: auto;
  position: absolute;
  top: 50%;
  right: 0;
  width: auto;
  transform: translateY(-50%);
  white-space: nowrap;

  .desktop > * {
    display: inline-block;
    list-style-type: none;
    height: 100%;
    vertical-align: 0;
    margin-right: 35px;
    white-space: nowrap;
    cursor: pointer;
  }

  a {
    color: rgb(26, 26, 26);
    text-decoration: none;
  }
}

.mobile {
  button {
    position: fixed;
    bottom: 10px;
    left: 50%;
    bottom: 10px;
    transform: translate(-50%, -50%);
    width: 200px;
  }

  .links {
    position: fixed;
    top: 70px;
    width: 100%;
    max-height: 0;
    height: auto;
    transform: none;
    overflow: hidden;
    transition: 700ms all;
    box-shadow: none;
    background-color: white;

    &.active {
      max-height: 100%;
      box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.205);
    }

    a {
      display: block;
      width: 100%;
      text-align: center;
      padding: 10px;
      font-size: 22px
    }
  }
} 

</style>