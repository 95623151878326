/* eslint-disable no-undef */
import Web3 from 'web3'
import { abi } from './lotteries.json'
import { bomb } from './contract.json'

export const web3Util = {
  async getAccount() {
    let accounts = await web3.eth.getAccounts();
    return accounts[0]
  },

  async getNetwork() {
    return await web3.eth.net.getId();
  },

  getLottery(address) {
    return new web3.eth.Contract(abi, address)
  },

  async getBombBalance() {
    let account = await this.getAccount()
    let bombContract = new web3.eth.Contract(bomb.abi, bomb.address);
    return await bombContract.methods.balanceOf(account).call()
  },

  async getApprovedAmount(spender) {
    let account = await this.getAccount()
    let bombContract = new web3.eth.Contract(bomb.abi, bomb.address);
    return await bombContract.methods.allowance(account, spender).call()
  },

  async approveBomb(spender, amount) {
    let account = await this.getAccount()
    let bombContract = new web3.eth.Contract(bomb.abi, bomb.address);
    await bombContract.methods.approve(spender, amount).send({from: account});
  },

  async getWonAmount(lottery) {
    let account = await this.getAccount()
    return await lottery.methods.balanceOf(account).call();
  },

  checkIfHasProvider() {
    if (window.ethereum || window.web3) {
      return true;
    } else {
      return false;
    }
  }
}

export const initializeWeb3 = async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum)
    await window.ethereum.request({ method: 'eth_requestAccounts' })
    return true
  }
  else if (window.web3) {
    window.web3 = new Web3(web3.currentProvider)
    return true
  }
  else {
    return false
  }
}
