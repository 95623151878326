import Vue from "vue";
import { initializeWeb3, web3Util } from "./util/getWeb3.js"

export const store = Vue.observable({
  isWeb3: false,
  isConnected: false,
  account: null,
  network: 250,
  balance: null
});

export const mutations = {
  initializeWeb3: async () => {
    await initializeWeb3();
  },
  updateAccount: async () => {
    store.network = await web3Util.getNetwork()
    store.account = await web3Util.getAccount()
    store.balance = await web3Util.getBombBalance()
    store.isConnected = true;
  }
}